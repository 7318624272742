@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.folder {
  min-width: 260px;
  background: $gray-100;
  transition: 0.1s background;
  height: 36px;
  &:hover {
    background: $gray-200;
  }
}

.summary {
  height: 32px;
  min-width: 170px;
  &:before {
    height: 30px !important;
  }
  & span.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.folderIcon {
  z-index: 1;
}
.paste {
  z-index: 1;
}
.leftSidebar {
  position: relative;
  &Active::before {
    background-color: $blue-300 !important;
  }
}

.leftSidebar::before {
  content: "";
  height: 100%;
  width: 32px;
  position: absolute;
  border: 1px solid $gray-700;
  background-color: $gray-700;
  right: 0;
  z-index: 1;
}
.dragPreview {
  background: $blue-50;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 2px;
    background: $blue-300;
    border-radius: 1px;
    top: 1px;
    left: 1px;
  }
}

.inputWrapper {
  width: 302px;
  position: relative;
}

.inputLengthLabel {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.pastePreviewButton {
  display: none;
}

.pastePreview {
  background: $gray-100;
  &:hover {
    background: $blue-50;
    border: 1px solid $blue-300 !important;
    .pastePreviewButton {
      display: flex !important;
    }
  }
}
