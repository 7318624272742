@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.configuratorModal {
  &Inner {
    &::-webkit-scrollbar {
      width: 5px !important;
      background: #fff;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 3px !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $gray-300 !important;
      border-radius: 3px !important;
    }
  }
}

.productList {
  overflow: hidden !important;
}
.specficationBody {
  max-height: calc(77vh - 150px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px !important;
    background: #fff;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 3px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-300 !important;
    border-radius: 3px !important;
  }
}
