@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  object-fit: contain;
}

.outlineNone {
  outline: none !important;
}
