@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.previewWindow {
  height: 24rem;
  background-color: $white;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    padding: 3px;
    border-radius: 3px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    padding: 3px;
    background: $gray-300 !important;
    border-radius: 3px !important;
  }
}

.logo {
  width: fit-content;
  height: 22px;
  object-fit: contain;
}

.modal {
  min-height: 44rem;
}
