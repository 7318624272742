@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.inputSearchWrapper {
  position: relative;

  display: flex;
  align-items: center;

  max-width: 21rem;
  min-width: 10rem;

  padding: 8px;

  border-bottom: 1px solid $gray-500;

  .inputSearch,
  .inputSearch:focus {
    width: 100%;

    padding: 0;

    border: none;

    background: transparent;

    font-size: 1rem;
    color: $gray-700;

    box-shadow: none;
    outline: none;
  }

  .inputSearchIcon {
    position: absolute;
    top: 8px;
    right: 8px;

    color: $gray-700;
  }
}

.inputFirefox[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1px !important;
  margin: 0 0.5rem 0 0;
}

.noTextSelection {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.less {
  input:focus {
    z-index: 1 !important;
  }
}
