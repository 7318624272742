@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.projectHeight {
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    padding: 3px;
    border-radius: 3px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    padding: 3px;
    background: $gray-300 !important;
    border-radius: 3px !important;
  }
}

.modal-content {
  min-height: 70vh;
  height: 70vh;
}

.bodyHeight {
  height: 75vh;
  min-height: 20rem;

  .projectStructureHeight {
    height: 80%;

    .projectTreeHeight {
      height: 90%;
    }
  }
}
