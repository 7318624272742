@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.footer {
  &Nav {
    position: relative;

    &Wrapper {
      background: $gray-700;
    }

    li {
      a {
        font-size: 1rem;
        color: $white;
        white-space: nowrap;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  &Copyright {
    color: $white;
    text-align: right;
    margin-bottom: 0;
    margin-top: 1rem;
    font-size: 1.25rem;
  }
}

@include media-breakpoint-down(lg) {
  .footer {
    &Nav {
      ul {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    &Nav {
      ul {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    &Nav {
      ul {
        grid-template-columns: 1fr;
      }
    }
  }
}
