@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.wrapper {
  min-height: 34px !important;
  border-color: $gray-300 !important;
  box-shadow: none !important;
  & ~ div {
    z-index: 2;
  }
}

.menuList {
  max-height: 296px !important;
  & > div {
    border-bottom: 1px solid $gray-300;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
      border-bottom: none;
    }
  }
}
