@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.modalBodyScrollbar {
  margin-right: 0.6rem !important;

  &::-webkit-scrollbar {
    width: 0.375rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-300;
    border-radius: 10px;
  }
}
