@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.loader {
  width: 220px;
  height: 220px;
}
.svg {
  width: 220px;
  height: 220px;

  & .path {
    transform-origin: center;
    transform: rotate(-90deg);
    stroke: $gray-300;
    stroke-width: 2px;
    animation: dash 5s linear infinite;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 130, 150;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: -130;
  }
}

