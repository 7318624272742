@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.fadeInLeft {
  animation: fadeInLeft 1.5s;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation: fadeInRight 1.5s;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInDown {
  animation: fadeInDown 1.5s;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInUp {
  animation: fadeInUp 1.5s;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.zoomIn {
  animation: zoomIn 1.5s;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
