@import "assets/stylesheets/common.scss";
@import "assets/stylesheets/_swiper.scss";

.hero-slider-container {
  position: relative;
  width: 1280px;
  height: 650px;
  margin-bottom: 35px;
  display: none;

  .hero-main-image-container {
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    z-index: -1;
    img {
      height: 650px;
      margin: 0 0 0 0 !important;
      object-fit: cover;
    }
  }

  .hero-text-container {
    position: absolute;
    top: 0;
    height: inherit;
    width: inherit;
    display: block;
    .swiper-pagination{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: 50%;
      top:5px;
      padding: 8px;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        background-color: #ccc;
        border-radius: 50%;
        margin: 0 5px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #000;
      }
    }
  }

  .hero-second-image-container {
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: rotate(180deg);
    top: -3px;
    // border: dotted 2px red;
    .img {
      margin: 0 0 0 0 !important;
      transform: rotate(180deg);
    }
  }
}

.image-slider {
  img {
    max-height: 650px;
    margin: 0 0 0 0 !important;
    object-fit: cover;
  }
}

.hero-slider-container:hover {
  .next-arrow,
  .prev-arrow {
    visibility: visible;
    opacity: 1;
  }
}

.text-container {
  position: relative;
  height: 650px;
  .slide-text {
    width: 50% !important;
    p {
      margin: 0;
    }
    .headline {
      line-height: 1.8;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .button {
      margin-top: 10px;
      text-transform: none;
      font-size: 17px;
      line-height: 35px;
    }
    .button-link {
      padding: 2px 10px 2px 15px;
      color: #fff;
      border-radius: 20px;
      background-image: linear-gradient(#4ab4cc, #389cbc);
      filter: none;
      border: none;
      border-top: 2px solid #6ac3d5;
      border-bottom: 2px solid #389cbc;
      &:hover {
        background-image: linear-gradient(#389cbc, #4ab4cc);
        filter: none;
        border-top: none;
        border-bottom: 2px solid #6ac3d5;
        border-top: 2px solid #389cbc;
      }
      span {
        display: block;
        height: 100%;
        float: left;
      }
    }
    .button- {
      &white {
        background: white !important;
        border: none !important;
        color: #333 !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &black {
        background: black !important;
        border: none !important;
        color: white !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &green {
        background: #4faf4d !important;
        border: none !important;
        color: white !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &blue {
        background: #399dbf !important;
        border: none !important;
        color: white !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &light_gray {
        background: #eee !important;
        border: none !important;
        color: #333 !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &dark_gray {
        background: #333 !important;
        border: none !important;
        color: white !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &trox_blue {
        background: #00375f !important;
        border: none !important;
        color: white !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
      &trox_link_blue {
        background: #25ace7 !important;
        border: none !important;
        color: white !important;
        span,
        &:after {
          color: inherit !important;
        }
      }
    }
  }
}

// Text content position classes

.slide-x-center {
  position: absolute;
  left: 25%;
  z-index: 1;
}
.slide-x-left {
  position: absolute;
  left: 5%;
  z-index: 1;
}
.slide-x-right {
  position: absolute;
  right: 5%;
  z-index: 1;
}

.slide-y-top {
  position: absolute;
  top: 7%;
  z-index: 1;
}
.slide-y-bottom {
  position: absolute;
  bottom: 1%;
  z-index: 1;
}

.slide-y-center {
  position: absolute;
  top: 33%;
  z-index: 1;
}

// Text alignment

.text-center {
  text-align: center;
  .button-link,
  .button-white,
  .button-black,
  .button-green,
  .button-blue,
  .button-light_gray,
  .button-dark_gray,
  .button-trox_blue,
  .button-trox_link_blue {
    margin: auto;
  }
}

.text-right {
  text-align: right;
  .button-link,
  .button-white,
  .button-black,
  .button-green,
  .button-blue,
  .button-light_gray,
  .button-dark_gray,
  .button-trox_blue,
  .button-trox_link_blue {
    display: flex;
    float: right;
  }
}

// Text styles

.font10 {
  font-size: 10px;
}
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font20 {
  font-size: 20px;
}
.font24 {
  font-size: 24px;
}
.font30 {
  font-size: 30px;
}
.font36 {
  font-size: 36px;
}
.font40 {
  font-size: 40px;
}
.font50 {
  font-size: 50px;
}
.font-bold {
  font-weight: bold;
}
.font-normal {
  font-weight: normal;
}
.font-normal-italic {
  font-weight: normal;
  font-style: italic;
}
.font-bold-italic {
  font-weight: bold;
  font-style: italic;
}

.white {
  color: white;
}
.black {
  color: black;
}
.euroventgreen {
  color: #4faf4d;
}
.blue {
  color: #399dbf;
}
.gray {
  color: var(--gray-600);
}
.gray-600 {
  color: #eee;
}
.gray-dark {
  color: #333;
}
.blue-light {
  color: #25ace7;
}
.blue-dark {
  color: #00375f;
}

// Media queries

@include media-breakpoint-up(sm) {
  .hero-slider-container {
    height: 391px;
    width: 100%;
    display: inline-block;
    .hero-main-image-container {
      img {
        height: 391px;
        width: 100%;
      }
    }
  }

  .text-container {
    height: 391px;
    .slide-text {
      right: 15px;
      .headline {
        font-size: 2.1875rem !important;
      }
      .button-link {
        line-height: 30px;
        font-size: 14px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-slider-container {
    height: 499px;
    width: 100%;
    .hero-main-image-container {
      img {
        height: 499px;
        width: 100%;
      }
    }
  }
  .text-container {
    height: 499px;
  }
}

@include media-breakpoint-up(lg) {
  .hero-slider-container {
    height: 522px;
    width: 100%;
    .hero-main-image-container {
      img {
        height: 522px;
        width: 100%;
      }
    }
  }

  .text-container {
    height: 522px;
    .slide-text {
      right: 45px;
    }
  }
}
