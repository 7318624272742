@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.filterTag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: fit-content;
    padding: 0.5rem;
    border: 1px solid #dee0e5;
    background: #fff;
    font-size: 1rem;
    line-height: 1;
    color: #4f5455;
    width: max-content;
  }