@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

/*** Header ***/

.configuratorHeader {
  height: 74px;
  width: 100%;
  position: fixed;
  z-index: 5;
  background-color: white;
  &WithGrayBar {
    border-bottom: 8px solid $gray-400;
    &.loader {
      position: fixed;
      width: 100%;
      &::before {
        content: "";
        width: 100%;
        height: 8px;
        background: linear-gradient(
          to right,
          rgba(0, 39, 95, 0) 0%,
          #25ace7 30%,
          rgba(0, 55, 95, 0) 60%
        );
        position: absolute;
        bottom: -8px;
        animation: loaderAnimation 5s linear infinite;
      }
    }
  }

  .cart {
    &Bubble {
      top: -5px;
      right: -5px;

      width: 20px;
      height: 20px;
    }

    &Number {
      font-size: 12px;
    }
  }
  @keyframes loaderAnimation {
    0% {
      background-position: -100vw 0;
    }
    100% {
      background-position: 100vw 0;
    }
  }
}
