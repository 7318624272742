@import "assets/stylesheets/_common.scss";

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.hero-container {
  margin: 0 auto;
  max-width: 1280px;
  max-height: 650px;
  display: initial;

  .slick-dots {
    bottom: -25px;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
.slide-container {
  position: relative;
  max-width: 1280px;
  height: 650px;
  margin: auto;
  margin-bottom: 50px;

  .slide-image {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 0px;
    border-width: 0px;
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: rotate(0deg) scale(1, 1);
    margin-left: 0px;
    margin-top: 0px;
    display: block;
    visibility: visible;
    animation: 1s ease-out 0s 1 slideInFromRight;
    img {
      width: 100%;
      height: 100%;
      max-height: 100% !important;
      object-fit: cover;
      margin-bottom: 0 !important;
    }
  }

  .slide-text {
    position: absolute;
    top: 40px;
    right: 120px;
    width: 600px;
    animation: 1s ease-in 0s 1 slideInFromRight;
    p {
      margin: 0;
    }
    .headline {
      font-size: 50px;
      line-height: 1.8;
      color: #25ace7;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .button-link {
      text-transform: none;
      font-size: 17px;
      line-height: 35px;
      padding: 2px 10px 2px 15px;
      color: #fff;
      border-radius: 20px;
      background-image: linear-gradient(#4ab4cc, #389cbc);
      filter: none;
      border: none;
      border-top: 2px solid #6ac3d5;
      border-bottom: 2px solid #389cbc;
      &:hover {
        background-image: linear-gradient(#389cbc, #4ab4cc);
        filter: none;
        border-top: none;
        border-bottom: 2px solid #6ac3d5;
        border-top: 2px solid #389cbc;
      }
      span {
        display: block;
        height: 100%;
        float: left;
      }
    }
  }
}
@include media-breakpoint-down(xl) {
  .hero-container {
    height: 522px;
  }

  .slide-container {
    height: 522px;
    .slide-text {
      width: 575px;
      right: 5px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .hero-container {
    height: 499px;
    min-height: 357px;
  }
  .slide-container {
    height: 499px;
  }
}

@include media-breakpoint-down(md) {
  .hero-container {
    height: 391px;
    display: initial;
    margin-left: 100px;
  }
  .slide-container {
    height: 391px;
    .slide-text {
      width: 400px;
      right: 10px;
      .headline {
        font-size: 2.1875rem;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .hero-container {
    display: none;
  }
}
