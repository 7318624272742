@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.scrollbar {
  max-height: 300px;
  overflow: auto;
  scrollbar-color: $red transparent;
  scrollbar-width: thin;
}
