@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.menu {
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 16rem;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}