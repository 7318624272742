@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.folder {
  background: $gray-100;
  border: 1px solid $gray-700;
  transition: 0.1s background;
  height: 36px;

  &:hover {
    background: $gray-200;
  }
}

.configuration {
  background: $white;
  border: 1px solid $gray-700;
  transition: 0.1s background;
  height: 36px;
}

.wizard {
  height: 100%;
  padding: 1px;
}

.wizardIndicator {
  height: 100%;
  width: 4px;
  background: $blue-300;
  border-radius: 1px;
}

.editMode {
  position: relative;

  background: $blue-50;
  border: 1px solid $blue-300;
  color: shade-color($blue-300, 30%);

  &:hover {
    background: $blue-50;
  }
}

.inputWrapper {
  width: 302px;
  position: relative;
}

.inputLengthLabel {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.pastePreviewButton {
  display: none;
}

.pastePreview {
  background: $gray-100;

  &:hover {
    background: $blue-50;
    border: 1px solid $blue-300 !important;

    .pastePreviewButton {
      display: flex !important;
    }
  }
}

.willReceiveDrop {
  border: 1px solid $blue-300 !important;
}

.overRoot {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border-bottom: 2px solid $blue-300;
    bottom: -0.6rem;
    left: 2.3rem;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    border: 2px solid $blue-300;
    width: 0.5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    bottom: -0.8rem;
    left: 1.9rem;
  }
}

.spacerContainer {
  height: 52px;
}

.bottomSpacer {
  height: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn .5s ease-in forwards;
}

.borderBetween div {
  border-right: 1px solid $gray-300;
}

.borderBetween div:last-child {
  border-right: none;
}

