@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.checkbox {
  background-color: $white;
  border: solid 1px $gray-700;
  border-radius: 1px;
  width: 100%;
  height: 100%;

  &.checked {
    background-color: $blue-300;
    border-color: $blue-300;
    color: $white;
  }

  input {
    visibility: hidden;
  }

  &Icon {
    transform: none !important;
  }
}
