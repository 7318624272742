@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.dropdown {
  &Menu {
    position: absolute;
    bottom: calc(100% - 0.25rem);
    right: 0;

    min-width: 164px;

    background: $white;

    border: solid 1px $gray-300;
    border-radius: 2px;
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);

    z-index: 1;

    &ToTop {
      top: auto;
      bottom: calc(100% - 0.25rem);
    }

    &Item {
      display: flex;
      align-items: flex-start;
      width: 100%;
      transition: 0.1s background-color;

      &:last-child {
        border: none;
      }
    }
  }
}
