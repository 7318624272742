@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.wizardProduct {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    height: 76px;
    width: 4px;
    background: $blue-300;
    border-radius: 1px;
    top: 2px;
    left: 1px;
  }
}
