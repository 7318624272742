@import "assets/stylesheets/common.scss";
@import "assets/stylesheets/_swiper.scss";

.gallery-widget {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 100px 0;
  position: relative;

  .slick-dots {
    bottom: -60px;
    top: auto;
    left: auto;
    right: auto;
    position: absolute;
    line-height: 0;

    li {
      border: 5px solid #fff;
      margin: 0;
      display: inline-block;
      width: auto;
      height: auto;

      button {
        margin: 0;
        height: auto;
        width: 100%;
        padding: 0;
        &:before {
          content: "";
          border: 3px solid $blue-600;
          display: block;
          max-width: 180px;
          overflow: hidden;
          float: none;
          position: absolute;
          width: 100%;
          height: 100%;
        }

        &.tab {
          clear: both;
          display: block;
          height: 50px;
         
        }

        .image {
          width: 180px;
          height: 270px;
          margin: 0;
        }
      }
    }

    li,
    button:before {
      margin: 0;
      padding: 0;
    }
  }

  .slick-slide {
    height: 100%;
  }

  .slick-list {
    max-height: 650px;
  }

  .slick-slider {
    margin: 0;
    padding: 0;
    position: relative;
    max-width: 100%;

    .slick-track {
      min-width: 100%;
    }

    li img {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }

  .slick-prev {
    left: 40px;
  }

  .slick-thumbs {
    display: none;
  }

  .slick-next {
    right: 40px;
  }
  .swiper{
    height: 600px;
  }

  .custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    flex-wrap: wrap;
    transform: translateY(-100%);
    width: 100%;
  }
  
  .custom-pagination-dot {
    width: 200px;
    height: 250px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
  }
  
  .custom-pagination-dot.active {
    background-color: white;
  }
  .swiper-slide{
    width: 100% !important;
    height: 900px;
  }
  
}

@include media-breakpoint-down(lg) {
  .gallery-widget {
    .slick-dots {
      bottom: -40px;

      li button .image {
        max-width: 100px;
        max-height: 66px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .gallery-widget {
    .slick-dots {
      bottom: -20px;

      li button .image {
        max-width: 40px;
        max-height: 26px;
      }
    }
  }
}
