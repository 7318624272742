.prev-arrow,
.next-arrow {
  width: 50px;
  height: 110px;
  background: url("sprite_mediastage-skin.png") 0 0 no-repeat;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -55px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: visibility 400ms, opacity 400ms ease-out;
}

.next-arrow {
  left: auto;
  right: 0;
  background: url("sprite_mediastage-skin.png") -145px 0 no-repeat;
}
