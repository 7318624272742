@import "../../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

$connector: 1px solid $gray-700;

.previewHeader {
  font-family: "HelveticaNeueW01-77BdCn 692722", sans-serif;
}

.svgLogoWrapper svg {
  width: auto;
  height: 18px;
  object-fit: contain;
}

.svgIcon svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  object-fit: contain;
}

.configuratorText {
  line-height: .75;
}

.scLayer {
  &:before {
    content: "";
    position: absolute;
    top: -32px;
    left: 26px;
    border-left: $connector;
    height: 32px;
  }
}

.switchLayer {
  &:before {
    content: "";
    position: absolute;
    top: -96px;
    left: 24px;
    border-left: $connector;
    width: 1px;
    height: 96px;
  }
}

.switchBus {
  top: 52px;
  width: calc(100% - 52px);
  transform: translateX(52px);
  z-index: -1;
}

.famLayer {
  &:before {
    content: "";
    position: absolute;
    top: 24px;
    left: -48px;
    border-top: 1px solid #4f5455;
    width: 48px;
    height: 1px;
  }
}

.famWithNeighbor {
  &:after {
    content: "";
    position: absolute;
    top: -32px;
    left: 1.7rem;
    border: $connector;
    border-radius: 2px 2px 0 0;
    border-bottom: none;
    width: calc(100% - 2px);
    height: 32px;
  }
}

@mixin modulesWrapper($left) {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: $left;
    border-bottom: $connector;
    border-left: $connector;
    border-radius: 0 0 0 2px;
    width: 32px;
    height: 48px;
  }
}

.modulesWrapper-0 {
  @include modulesWrapper(24px);
  &:after {
    content: "max. lenght of line: 370m";
    direction: rtl;
    transform: rotate(-90deg);
    color: $gray-400;
    position: absolute;
    top: 0;
    left: 0;
    border-left: 1px dashed $gray-400;
    border-radius: 2px 2px 0 0;
    border-bottom: none;
    width: 14px;
    height: 100%;
  }
}

.modulesWrapper-4 {
  @include modulesWrapper(16px);
}
.modulesWrapper-3 {
  @include modulesWrapper(20px);
}
.modulesWrapper-2 {
  @include modulesWrapper(24px);
}
.modulesWrapper-1 {
  @include modulesWrapper(28px);
}

.moduleWithNeighbor {
  &:after {
    content: "";
    position: absolute;
    top: 50px;
    left: -20px;
    border: $connector;
    border-radius: 2px 0 0 2px;
    border-right: none;
    height: calc(100% - 2px);
    width: 20px;
  }
}

$pt: 12px;

.lessThanFourRightChildren {
  & > div {
    padding-top: $pt;

    &:before {
      content: "";
      position: absolute;
      border-bottom: $connector;
      border-left: $connector;
      top: 50%;
      height: 3px;
      border-radius: 0 0 0 2px;
    }
    &:after {
      content: "";
      position: absolute;
      border-top: $connector;
      border-right: $connector;
      border-radius: 0 2px 0 0;
    }

    &:nth-child(1) {
      padding-top: 0;
      &:before {
        border-left: none;
        height: 0;
        border-radius: 0;
        left: -48px;
        width: 48px;
      }
      &:after {
        display: none;
      }
    }
    &:nth-child(2) {
      &:before {
        left: -17px;
        width: 17px;
      }
      &:after {
        top: -22px;
        height: 54px;
        left: -48px;
        width: 32px;
      }
    }
    &:nth-child(3) {
      &:before {
        left: -21px;
        width: 21px;
      }
      &:after {
        top: -80px;
        height: 113px;
        left: -48px;
        width: 28px;
      }
    }
    &:nth-child(4) {
      &:before {
        left: -25px;
        width: 25px;
      }
      &:after {
        top: -138px;
        height: 171px;
        left: -48px;
        width: 24px;
      }
    }
  }
}

.moreThanFourRightChildren {
  & > div {
    padding-top: $pt;

    &:before {
      content: "";
      position: absolute;
      border-bottom: $connector;
      border-left: $connector;
      top: var(--top, 50%);
      height: 6px;
      border-radius: 0 0 0 2px;
    }
    &:after {
      content: "";
      position: absolute;
      border-top: $connector;
      border-right: $connector;
      border-radius: 0 2px 0 0;
    }

    &:nth-child(1) {
      padding-top: 0;
      &:before {
        top: 18px;
        left: -9px;
        width: 9px;
      }
      &:after {
        top: 4px;
        height: 16px;
        left: -48px;
        width: 40px;
      }
    }
    &:nth-child(2) {
      &:before {
        left: -13px;
        width: 13px;
      }
      &:after {
        top: calc(-42px - var(--additionalHeight));
        height: calc(76px + var(--additionalHeight));
        left: -48px;
        width: 36px;
      }
    }
    &:nth-child(3) {
      &:before {
        left: -17px;
        width: 17px;
      }
      &:after {
        top: calc(-100px - var(--additionalHeight));
        height: calc(134px + var(--additionalHeight));
        left: -48px;
        width: 32px;
      }
    }
    &:nth-child(4) {
      &:before {
        left: -21px;
        width: 21px;
      }
      &:after {
        top: calc(-156px - var(--additionalHeight));
        height: calc(188px + var(--additionalHeight));
        left: -48px;
        width: 28px;
      }
    }
    &:nth-child(5) {
      &:before {
        left: -25px;
        width: +25px;
      }
      &:after {
        top: calc(-214px - var(--additionalHeight));
        height: calc(244px + var(--additionalHeight));
        left: -48px;
        width: 24px;
      }
    }
    &:nth-child(6) {
      &:before {
        left: -29px;
        width: 29px;
      }
      &:after {
        top: calc(-272px - var(--additionalHeight));
        height: calc(306px + var(--additionalHeight));
        left: -48px;
        width: 20px;
      }
    }
    &:nth-child(7) {
      &:before {
        left: -33px;
        width: +33px;
      }
      &:after {
        top: calc(-330px - var(--additionalHeight));
        height: calc(364px + var(--additionalHeight));
        left: -48px;
        width: 16px;
      }
    }
    &:nth-child(8) {
      &:before {
        left: -37px;
        width: +37px;
      }
      &:after {
        top: calc(-388px - var(--additionalHeight));
        height: calc(422px + var(--additionalHeight));
        left: -48px;
        width: 12px;
      }
    }
    &:nth-child(9) {
      &:before {
        left: calc(-40px - 1px);
        width: calc(40px + 1px);
      }
      &:after {
        top: calc(-446px - var(--additionalHeight));
        height: calc(480px + var(--additionalHeight));
        left: -48px;
        width: 8px;
      }
    }
    &:nth-child(10) {
      &:before {
        left: -61px;
        width: +61px;
      }
      &:after {
        top: calc(-504px - var(--additionalHeight));
        height: calc(538px + var(--additionalHeight));
        left: -48px;
        width: 4px;
      }
    }
  }
}

.linesFromNodeToBottomPlacedChildren {
  & > div {
    position: absolute;
    top: 52px;
    border-left: $connector;
    width: 2px;
    height: calc(100% - 24px);
    left: var(--left);

    &:last-child {
      height: auto;
      left: 5px;
      direction: rtl;
      transform: rotate(-90deg);
    }
  }
}

.node-0 {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -36px;
    width: 36px;
    height: calc(100% - 24px);
    border-bottom: $connector;
    border-left: $connector;
    border-radius: 0 0 0 2px;
    transform: translateY(-100%);
  }
}

.wrapper-4-4 {
  &:before {
    content: "";
    position: absolute;
    border-right: $connector;
    top: 24px;
    height: 100%;
    left: -52px;
    width: 5px;
  }
  &:after {
    content: "";
    position: absolute;
    border-right: $connector;
    border-left: $connector;
    top: 24px;
    height: calc(100% + 26px);
    left: -44px;
    width: 5px;
  }
}

.wrapper-4-3 {
  &:before {
    content: "";
    position: absolute;
    border-right: $connector;
    top: 24px;
    height: calc(100% + 23px);
    left: -52px;
    width: 5px;
  }
  &:after {
    content: "";
    position: absolute;
    border-left: $connector;
    top: 24px;
    height: calc(100% + 23px);
    left: -44px;
    width: 5px;
  }
}

.wrapper-4-2 {
  &:before {
    content: "";
    position: absolute;
    border-right: $connector;
    top: 32px;
    height: 100%;
    left: -52px;
    width: 5px;
  }
}

.node-1 {
  &:before {
    content: "";
    position: absolute;
    border-bottom: $connector;
    border-left: $connector;
    top: 50%;
    transform: translateY(-100%);
    height: 1px;
    border-radius: 0 0 0 2px;
    left: -40px;
    width: 40px;
  }
}

.wrapper-3-3 {
  &:after {
    content: "";
    position: absolute;
    border-right: $connector;
    border-left: $connector;
    top: 24px;
    height: calc(100% + 24px);
    left: -44px;
    width: 5px;
  }
}

.node-2 {
  &:before {
    content: "";
    position: absolute;
    border-bottom: $connector;
    border-left: $connector;
    top: 50%;
    transform: translateY(-100%);
    height: 100%;
    border-radius: 0 0 0 2px;
    left: -44px;
    width: 44px;
  }
}

.wrapper-2-2 {
  &:after {
    content: "";
    position: absolute;
    border-right: $connector;
    top: 24px;
    height: calc(100% + 24px);
    left: -44px;
    width: 5px;
  }
}

.wrapper-3-2 {
  &:after {
    content: "";
    position: absolute;
    border-right: $connector;
    top: 48px;
    height: calc(100% - 48px);
    left: -48px;
    width: 5px;
  }
}

.summary_tab {
  height: 100vh;
}

.item {
  height: 52px;
}

.summary_view{
  padding-left: 10.3%;
  padding-right: 10.3%;
  height: fit-content;
  max-height: fit-content;
}