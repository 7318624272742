@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.label {
  position: absolute;
  z-index: 2;
  color: $gray-400;
  left: 0.5rem;
  top: -0.5rem;
  background-color: white;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0 3px;
}

.wrapper {
  text-align: left;
  position: relative;
  height: 38px;
}

.textInput {
  position: absolute;
  width: 100%;
  &:focus {
    box-shadow: none !important;
    border: 1px solid $blue-300;
  }
}

.error {
  border: 1px solid $red !important;
}

.icon {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
