
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/navigation";

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }