@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

 .arrowRight {
    transform: translateX(35%);
  }

  .arrowDown {
    transform: rotate(180deg);
  }
