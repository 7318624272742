@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

div.fieldWithoutMarginBottom {
  margin-bottom: 0 !important;
}

.modalMargin{
  padding-right: 10rem ;
  padding-left: 10rem ;
  @media only screen and (max-width: 768px) {
    padding-right: 2rem ;
    padding-left: 2rem;
  }
}
