@import "../../assets/stylesheets/bootstrap/config/grid";
@import "../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

$leftSidebarBaseWidth: 120px;
$leftSidebarExtendedWidth: 468px;
$NavConfiguratorHeight: 74px;
$contentHeightWithoutHeader: calc(100vh - $NavConfiguratorHeight);
$leftSidebarSubnavWidth: $leftSidebarExtendedWidth - $leftSidebarBaseWidth;
$subnavTransition: 0.3s ease-in-out max-width;
$overlayTransition: 0.3s ease-in-out opacity;
$sideBarWrapperTransition: 0.3s ease-in-out;

.leftSidebar {
  width: $leftSidebarBaseWidth;
  background: $gray-700;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.25);
  z-index: 5;
  position: fixed;
  top: $NavConfiguratorHeight;
  height: $contentHeightWithoutHeader;

  &Accordion {
    &Item {
      border-bottom: thin solid $gray-500;

      :global {
        .accordion-button {
          padding: 0;
          background: transparent;
          color: $white;
          border: none;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: inherit;
        }

        .accordion-body {
          font-size: 16px;
          color: $white;
          white-space: nowrap;
        }

        .svg-inline--fa {
          transition: 0.2s all;
        }
      }

      &Opened {
        :global {
          .svg-inline--fa {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &Button {
    aspect-ratio: 1;
    color: $gray-500;
    font-size: 16px;
    column-gap: 7px;
    background: $gray-700;

    &Active {
      :global .svg-inline--fa {
        color: $blue-300;
      }
    }

    &:hover {
      color: $white;

      :global .svg-inline--fa {
        color: $blue-300;
      }
    }

    &Focused {
      background: $gray-800;
      color: $white;

      :global .svg-inline--fa {
        color: $blue-300;
      }
    }
  }

  &SubNav {
    max-width: 0;
    width: $leftSidebarSubnavWidth;
    background: $gray-700;
    overflow: hidden;
    transition: $subnavTransition;
    z-index: 4;
    left: $leftSidebarBaseWidth;
    top: $NavConfiguratorHeight;
    bottom: 0;

    &Wrapper {
      width: $leftSidebarSubnavWidth;
    }

    &Opened {
      max-width: $leftSidebarSubnavWidth;
    }

    &Dark {
      background: $gray-800;
    }

    &Link {
      color: $white;
      min-height: 78px;

      > div {
        max-width: calc(100% - 30px);
      }

      &Title {
        white-space: nowrap;
        color: $white !important;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      &Subtitle {
        white-space: nowrap;
        color: $white !important;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      :global .svg-inline--fa {
        color: $white;
      }

      &Active,
      &:hover {
        color: $white;
        background: $blue-300;
      }

      &Disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    &Item {
      color: $gray-500;
      cursor: pointer;
      white-space: nowrap;

      &Active,
      &:hover {
        color: $white;
        background: $gray-800;

        &:after {
          content: "";
          display: block;
          width: 8px;
          background: $blue-300;
          height: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          position: absolute;
        }
      }

      &Active {
        background: $gray-800;

        &:hover {
          background: $gray-800;
        }
      }
    }

    &Title {
      color: $white;
      min-height: 78px;
    }
  }

  &Wrapper {
    top: $NavConfiguratorHeight;
    max-width: $leftSidebarBaseWidth;
    max-height: $contentHeightWithoutHeader;
    transition: $subnavTransition;
    opacity: 1;

    &Extended {
      max-width: $leftSidebarExtendedWidth;
    }
  }

  &Overlay {
    width: 0;
    transition: $overlayTransition;
    opacity: 0;
    left: 0;
    top: 74px;
    bottom: 0;
    background-color: $gray-700;
    z-index: 4;

    &Active {
      width: 100%;
      opacity: 0.8;
    }
  }

  &Portal {
    max-height: $contentHeightWithoutHeader;

    &SubNav {
      max-width: $leftSidebarSubnavWidth;
    }

    &Wrapper {
      &Extended {
        z-index: 1;
      }
    }
  }
}

.leftSidebarFinalSubnavWrapper:not(isFirstSideBarOpen) {
  width: $leftSidebarSubnavWidth;

  transition:
    0.3s width,
    0.3s opacity;
}
.toggleBtnContainer {
  z-index: 9999;
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  &Right {
    right: -4px;
  }
  &Left {
    left: -4px;
  }
  .toggleBtn {
    z-index: 9999;
    writing-mode: vertical-lr;
    &Right {
      right: -30px;
      border-radius: 0 4px 4px 0;
    }
    &Left {
      left: -30px;
      border-radius: 4px 0px 0px 4px;
    }
    .toggleBtnText {
      letter-spacing: 1px;
      transform: rotate(180deg);
    }
  }
}

.leftSidebarFinalSubnavWrapper.isFirstSideBarOpen {
  width: 0;

  .isFirstSideBarOpenContent {
    width: 0;
    opacity: 0;
  }
}

.zIndex5 {
  z-index: 5;
}

.sideBarWrapper {
  transition: $sideBarWrapperTransition;
}

.customScrollBar {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    margin-right: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $gray-700 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-500 !important;
    border-radius: 3px !important;
  }
}

.rightSidebar {
  &Wrapper {
    width: 0;
    transition: $sideBarWrapperTransition;

    &Extended {
      width: 20.75rem;
      transition: $sideBarWrapperTransition;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: $gray-700;
        z-index: 2;
      }
    }
  }

  &Toggle {
    width: 32px;
    padding: 10px 6px;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 100%;
    top: 0;
    z-index: 2;

    :global {
      .svg-inline--fa {
        transition: 0.2s all;
        color: white;
      }
    }

    &Extended {
      :global {
        .svg-inline--fa {
          transform: rotateZ(180deg);
        }
      }
    }
    & span {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }
}
